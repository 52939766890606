import type { LinkDescriptor } from "@remix-run/node";
import type mapboxgl from "mapbox-gl";
import type { Map as MapLibreMap } from "maplibre-gl";

// ToDo: Move to sprite sheet, use SDF to handle colors

export const CPT_ICON_NAMES = [
  "cpt-draft",
  "cpt-level-1",
  "cpt-level-1-non-digitised",
  "cpt-level-2",
  "cpt-level-2-non-digitised",
  "cpt-level-3",
  "cpt-level-3-non-digitised",
  "cpt-level-4",
  "cpt-level-4-non-digitised",
  "cpt-selected",
  "cpt-proposed",
];

export const BH_ICON_NAMES = [
  "mb-logging-in-progress",
  "mb-draft",
  "mb-draft-non-digitised",
  "mb-level-1",
  "mb-level-1-non-digitised",
  "mb-level-2",
  "mb-level-2-non-digitised",
  "mb-level-3",
  "mb-level-3-non-digitised",
  "mb-level-4",
  "mb-level-4-non-digitised",
  "mb-selected",
  "mb-proposed",
];

export const TP_ICON_NAMES = [
  "tp-logging-in-progress",
  "tp-draft",
  "tp-draft-non-digitised",
  "tp-level-1",
  "tp-level-1-non-digitised",
  "tp-level-2",
  "tp-level-2-non-digitised",
  "tp-level-3",
  "tp-level-3-non-digitised",
  "tp-level-4",
  "tp-level-4-non-digitised",
  "tp-selected",
  "tp-proposed",
];

export const HA_ICON_NAMES = [
  "ha-logging-in-progress",
  "ha-draft",
  "ha-draft-non-digitised",
  "ha-level-1",
  "ha-level-1-non-digitised",
  "ha-level-2",
  "ha-level-2-non-digitised",
  "ha-level-3",
  "ha-level-3-non-digitised",
  "ha-level-4",
  "ha-level-4-non-digitised",
  "ha-selected",
  "ha-proposed",
];

export const DCP_ICON_NAMES = [
  "dcp-logging-in-progress",
  "dcp-draft",
  "dcp-draft-non-digitised",
  "dcp-level-1",
  "dcp-level-1-non-digitised",
  "dcp-level-2",
  "dcp-level-2-non-digitised",
  "dcp-level-3",
  "dcp-level-3-non-digitised",
  "dcp-level-4",
  "dcp-level-4-non-digitised",
  "dcp-selected",
  "dcp-proposed",
];

export const SEISMIC_ICON_NAMES = [
  "seismic-draft",
  "seismic-level-1",
  "seismic-level-1-non-digitised",
  "seismic-level-2",
  "seismic-level-2-non-digitised",
  "seismic-level-3",
  "seismic-level-3-non-digitised",
  "seismic-level-4",
  "seismic-level-4-non-digitised",
  "seismic-selected",
  "seismic-proposed",
];

export const DMT_ICON_NAMES = [
  "dmt-draft",
  "dmt-level-1",
  "dmt-level-1-non-digitised",
  "dmt-level-2",
  "dmt-level-2-non-digitised",
  "dmt-level-3",
  "dmt-level-3-non-digitised",
  "dmt-level-4",
  "dmt-level-4-non-digitised",
  "dmt-selected",
  "dmt-proposed",
];

export const CPT_MAP_IMAGE_LINKS: LinkDescriptor[] = CPT_ICON_NAMES.map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/cpt/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const BOREHOLE_MAP_IMAGE_LINKS: LinkDescriptor[] = BH_ICON_NAMES.map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/machine-borehole/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const TEST_PIT_MAP_IMAGE_LINKS: LinkDescriptor[] = TP_ICON_NAMES.map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/test-pit/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const DCP_MAP_IMAGE_LINKS: LinkDescriptor[] = DCP_ICON_NAMES.map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/dynamic-cone-penetrometer/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const HAND_AUGER_MAP_IMAGE_LINKS: LinkDescriptor[] = HA_ICON_NAMES.map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/hand-auger/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const SEISMIC_MAP_IMAGE_LINKS: LinkDescriptor[] = SEISMIC_ICON_NAMES.map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/seismic/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const DMT_MAP_IMAGE_LINKS: LinkDescriptor[] = DMT_ICON_NAMES.map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/dmt/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const MISC_IMAGE_LINKS: LinkDescriptor[] = ["point"].map((link) => {
  return {
    rel: "prefetch",
    href: `/assets/${link}.png`,
    as: "image",
    type: "image/png",
  };
});

export const MAP_IMAGE_LINKS = [
  ...CPT_MAP_IMAGE_LINKS,
  ...BOREHOLE_MAP_IMAGE_LINKS,
  ...HAND_AUGER_MAP_IMAGE_LINKS,
  ...TEST_PIT_MAP_IMAGE_LINKS,
  ...SEISMIC_MAP_IMAGE_LINKS,
  ...DMT_MAP_IMAGE_LINKS,
  ...MISC_IMAGE_LINKS,
];

export const MAP_ICONS_TO_LOAD = {
  cpt: CPT_ICON_NAMES,
  [`machine-borehole`]: BH_ICON_NAMES,
  [`test-pit`]: TP_ICON_NAMES,
  [`hand-auger`]: HA_ICON_NAMES,
  [`dynamic-cone-penetrometer`]: DCP_ICON_NAMES,
  [`seismic`]: SEISMIC_ICON_NAMES,
  [`dmt`]: DMT_ICON_NAMES,
};

export const loadMapIcons = (map: mapboxgl.Map | MapLibreMap, icons: [string, string[]][]) => {
  icons.forEach(([key, icons]) => {
    icons.forEach((icon) => {
      if (map.hasImage(icon)) {
        return;
      }
      map.loadImage(`/assets/${key}/${icon}.png`, (error, image) => {
        if (error ?? !image) {
          throw error;
        }
        /**
         * Double check before attempting to handle just incase there are multiple hooks
         * attempting to load the icons
         */
        if (map.hasImage(icon)) {
          return;
        }
        map.addImage(icon, image);
      });
    });
  });
};
